<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col md="12" class="px-0">
          <div class="content-left history pt-4 pb-0">
            <!-- <Sidebar /> -->
            <div class="main-left mb-2">
              <div class="row mx-1" style="overflow-x: scroll;" >
                <div class="col-md-12 mb-5 mt-3" style="overflow-x: scroll;" >
                  <CancelledOrdersCard />
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListInfoHistory from '@/components/molecules/ListInfoHistory';
import Sidebar from '@/components/layout/MainSidebar';
import CancelledOrdersCard from '@/components/organisms/CancelledOrdersCard';
import ChartCard from '@/components/organisms/ChartCard';
// @ is an alias to /src
import '@/assets/css/style.css';
export default {
  name: 'History',
  components: {
    Sidebar,
    ChartCard,
    ListInfoHistory,
    CancelledOrdersCard,
  },
  data() {
    return {};
  },
};
</script>
<style>
@media screen and (max-width:768px) {
  .row{
    width:86vw;
  }
}
</style>