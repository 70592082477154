<template>
  <div class="home">
    <b-container fluid style="overflow-y: hidden">
      <b-row>
        <b-col v-if="isHome && getDetailUser.roleId !== 3" lg="3" md="3" sm="12" class="cart-container" id="content-left" style="  background-color: #F4EADE;">
          <CartCheckout />
        </b-col>
        <b-col :class="[
          isHome && getDetailUser.roleId !== 3 ? 'col-lg-9 col-md-9' : 'col-lg-12 col-md-12',
        ]" sm="12" class="px-0">
          <div class="content-left pt-4">
            <slot></slot>
            <Sidebar />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Sidebar from '@/components/layout/MainSidebar';
import CartCheckout from '@/components/organisms/CartCheckout';

export default {
  name: 'MainContainer',
  props: {
    isHome: Boolean,
  },
  components: {
    Sidebar,
    CartCheckout,
  },
  computed: {
    ...mapGetters('user', ['getDetailUser']),
  },
};
</script>
<style>
.cart-container{
  box-shadow: 6px 0px 10px rgba(0, 0, 0, 0.1);
}
</style>