<template>
  <div class="input-group ">
    <div class="input-group-prepend">
      <button
        class="btn rounded-0 min-btn"
        @click="minQtyCartComp(product, addons)"
        type="button"
      >
        -
      </button>
    </div>
    <input
      type="number"
      min="1"
      v-model="totalQty"
      :placeholder="qty"
      @keyup.enter="changeTotalQtyComp(product, addons)"
      class="form-control total-ord custom-chart-input"
      aria-label="Example text with button addon"
      aria-describedby="button-addon1"
      style="background-color:#F4EADE; border: none;"
    />
    <button
      @click="plusQtyCartComp(product, addons)"
      class="btn ml-min rounded-0 add-btn"
      type="button"
    >
      +
    </button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "CartInput",
  props: ["qty", "product", "addons"],
  data() {
    return {
      totalQty: "",
    };
  },
  methods: {
    ...mapMutations("cart", ["MIN_QTY_CART", "PLUS_QTY_CART", "CHANGE_TOTAL_QTY"]),
    minQtyCartComp(product, addons) {
      this.MIN_QTY_CART({product, addons});
      this.totalQty = "";
    },
    plusQtyCartComp(product, addons) {
      this.PLUS_QTY_CART({product, addons});
      this.totalQty = "";
    },
    changeTotalQtyComp(product, addons) {
      if (this.totalQty < 1) {
        this.totalQty = "";
      }
      this.CHANGE_TOTAL_QTY({ product, qty: this.totalQty, addons });
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.input-group{
  border:1px solid #0f3442;
  border-radius:6px;
  display:flex;
  flex-direction: row;
  justify-content: center;
  width:max-content
}
.min-btn , .add-btn{
  font-size:20px;
}
.total-ord{
  font-size:20px;
}
@media screen and (max-width:768px) {
  .input-group{
    width:100px;
  }
  .min-btn,.add-btn{
width:max-content;
}
}
@media screen and (max-width:1024px) {
  .input-group{
    width:100px;
  }
  .min-btn,.add-btn{
width:max-content;
}
}

.input-group {
  flex-direction: row;
}
</style>
