<template>
  <div style="overflow-x:hidden; overflow-y:auto" class="removeDefaultPadding">

    <div class="row " style="margin-left:5px;">
      <div v-if="categoryProducts.products" class="d-flex flex-wrap products">
        <div
          class="col-sm col-md-5 col-lg-2 my-2 border cursor-pointer d-flex align-items-center justify-content-center back-button"
          @click="handleGoBack" style="background-color:#9DB5BE;
  font-weight:900;
  color:#fff; border-radius:14px;">
          <b-icon-arrow-left class="h1"></b-icon-arrow-left>
        </div>

        <div class="col-sm col-md-5 col-lg-2 my-1 border product-item removeDefaultPadding"
          style="background-color: #F4EADE; box-shadow: 3px 1px 5px rgba(0, 0, 0, 0.15);
 border-radius: 14px;
  font-weight:900;
  color:#0f3442;"
          v-for="product in categoryProducts.products" :key="product.id" :class="{
            'cursor-pointer': product.stock && product.stock.available_stock > 0,
            'cursor-not-allowed': product.stock && (!product.stock.is_enabled || product.stock.available_stock <= 0)
          }"
          @click="product.stock && product.stock.is_enabled && product.stock.available_stock > 0 ? showModal('addons', product) : null"
          v-b-modal.modal-primary>
          <div class="position-relative">
            <div>
              <img :src="product.preview_url" :alt="product.name" class="img-fluid mx-auto"
                style="height:150px; border-radius: 10px;" />
            </div>
            <div v-if="product && product.stock && product.stock.tags && product.stock.tags.length > 0">
              <img v-for="(tag, index) in JSON.parse(product.stock.tags)" :key="index" :src="tag"
                :alt="'Tag ' + (index + 1)" :class="{ 'left-tag': index == 0, 'right-tag': index == 1 }" width="30" />
            </div>
          </div>
          <p class="text-sm text-gray-700 text-center text-small p-2">{{ product.name }}</p>
          <p class="text-sm font-bold text-gray-700 text-center text-small">{{ product.sale_price | currency }}</p>
        </div>
      </div>
    </div>

    <AddonModal :whatModal="getWhatModal" :selectedProduct="selectedProduct" />
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapMutations } from 'vuex';
import AddonModal from './AddonModal.vue';

export default {
  name: 'ProductItem',
  mixins: [mixins],
  components: {
    AddonModal,
  },
  data() {
    return {
      whatModal: '',
      selectedProduct: null,
    };
  },
  mounted() {
    for (const product of this.categoryProducts.products) {
      product.preview_url =
        product.media.length !== 0
          ? product.media.find((media) => media.pivot.primary === 1).url
          : product.preview_url;
    }
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    ...mapMutations('category', ['SET_PRODUCT_ADDONS']),
    ...mapMutations('cart', ['ADD_TO_CART', 'REMOVE_PRODUCT_FROM_CART']),
    handleGoBack() {
      this.$emit('goBack');
    },
    showModal(val, product) {
      console.log(val)
      if (product.addons.length > 0) {
        this.SET_WHAT_MODAL(val);
        this.SET_PRODUCT_ADDONS(product);
        this.selectedProduct = product;
      } else {
        this.SET_WHAT_MODAL('');
        this.SET_PRODUCT_ADDONS(null);
        this.ADD_TO_CART({
          product: {
            id: product.id,
            name: product.name,
            sale_price: product.sale_price,
            preview_url: product.preview_url,
          },
          addons_id: [],
          addons: [],
          qty: 1,
        });
      }
    },
  },
  computed: {
    ...mapGetters('category', ['categoryProducts']),
    ...mapGetters('modal', ['getWhatModal']),
  },
};
</script>

<style scoped>
.back-button {
  height: 220px;
  width: 780px;
  border-radius: 3px;
}

.product-title {
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

cursor-pointer:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.left-tag {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin-left: 0px !important;
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.right-tag {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin-left: 0px !important;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.text-small {
  padding: 0px 15px 0px 15px;
  font-size: 13px;
  margin-bottom: 0px;
}

.removeDefaultPadding {
  padding: 3px;
}
@media screen and (max-width:1024px) {
  .products{
/* margin-right:45px; */
max-width:60vw;
  } 
}
@media screen and (min-width:768px) {
  .products{
margin-right:45px;
gap:10px;
  } 
}
@media screen and (max-width:768px) {
  .products{
    max-width:51vw;
   /* max-width:50%; */
  }
  .product-item{
    width:150px;
  }
}
</style>