<template>
  <g-card :isHeader="false">
    <template #customHeader>
      <div class="px-1 pt-4">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="font-weight-bold">Cancelled Orders</h5>
        </div>
      </div>
    </template>
    <template #cardBody>
      <CancelledOrders />
    </template>
  </g-card>
</template>

<script>
import { mapActions } from 'vuex';
import CancelledOrders from '@/components/molecules/CancelledOrders';
export default {
  name: 'PendingOrdersCard',
  components: {
    CancelledOrders,
  },
  data() {
    return {
      filterVal: 'all',
    };
  },
  methods: {
    ...mapActions('history', ['filterHistory']),
  },
};
</script>

<style scoped>



</style>
