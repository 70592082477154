<template>
    <div>
        <div class="main-cart mt-5" style="max-height: 100%; overflow-y: auto;">
    
            <CartList v-if="carts.length > 0" style="max-height: 40vh; overflow-y: auto" />
            <CartEmpty v-if="carts.length < 1" />
            <b-form-textarea v-if="carts.length > 0" id="textarea" v-model="note" class="order-note" placeholder="Enter order note here..." rows="1" no-resize></b-form-textarea>
    
            <div class="position cart-details" style=" z-index: 99;">
                <b-container v-if="carts.length > 0" class="card my-2">
                    <b-row>
                        <b-col class="order-type ">Order type:<span @click="setOrderProps('Order Type')" v-b-modal.modal-primary class="clickable"> {{
                  getOrderType
                }}</span></b-col>
    
                        <b-col v-if="getCustomer">Customer: {{ getCustomer.phone }}</b-col>
                    </b-row>
                </b-container>
    
                <div v-if="carts.length > 0" class="checkout py-1">
                    <b-button @click="setOrderProps('Apply Discount')" v-b-modal.modal-primary size="sm" class="py-2 mb-2 border-0 discount-btn">Apply discount</b-button>
    
                    <b-button @click="setOrderProps('Login')" v-b-modal.modal-primary variant="outline-primary" size="sm" class="py-2 mb-2 border-0 discount-btn">{{ getCustomer ? "Change Customer" : "Login Customer" }}</b-button>
    
                    <b-button @click="setOrderProps('Payment Type')" v-b-modal.modal-primary variant="outline-primary" size="sm" class="py-2 mb-2 border-0 discount-btn">Payment Type</b-button>
                    <div class="d-flex font-weight-bold justify-content-between my-1">
                        <span>Total:</span>
                        <span>
                  {{ cartTotalPrice | currency }}
                </span>
                    </div>
                    <div class="d-flex font-weight-bold justify-content-between my-1">
                        <span v-if="getDiscountAmount > '0'">Discount:</span>
    
                        <span v-if="getDiscountAmount > '0' && getDiscountType == 'flat'">{{
                  getDiscountAmount > 0 ? getDiscountAmount + '$' : '$' + 0
                }}</span>
    
                        <span v-if="getDiscountAmount > '0' && getDiscountType == 'percentage'">{{
                  getDiscountAmount > 0 ? getDiscountAmount + '%' : '%' + 0
                }}</span>
                    </div>
                    <div class="d-flex font-weight-bold justify-content-between my-1">
                        <span>GST Tax:</span>
                        <span>{{ getGstAmount | currency }}</span>
                    </div>
                    <div class="d-flex font-weight-bold justify-content-between my-1">
                        <span>Amount to pay:</span>
                        <span>{{ getTotalAmount | currency }}</span>
                    </div>
    
                    <div class="d-flex font-weight-bold justify-content-between my-1">
                        <b-form-checkbox v-if="getCustomer && getCustomer.cashback_points > 0" id="cashback-checkbox" v-model="cashback" name="checkbox-1" :value="true" size="sm" :unchecked-value="false">
                            <strong>
                    You have {{ getCustomer && getCustomer.cashback_points }} cashback points, would you
                    like to use them?
                  </strong>
                        </b-form-checkbox>
                    </div>
    
                    <div>
                        <template>
                  <div>
                    <!-- <b-button
                      v-if="getCustomer"
                      @click="setOrderProps('Order Type')"
                      v-b-modal.modal-primary
                      variant="primary"
                      class="btn font-weight-bold py-2 mb-2 btn-block border-0"
                    >
                      Continue
                    </b-button> -->
                    <b-button @click="showModal" v-b-modal.modal-primary 
                      class="btn font-weight-bold py-2 mb-2 btn-block border-0 place-order-btn">
                      Place Order
                    </b-button>
                  </div>
</template>
    
            <b-button @click="clearCart" class="btn font-weight-bold py-2 btn-block cancel-order-btn">Cancel</b-button>
          </div>
        </div>
      </div>  
    </div>
    <ModalOrder :whatModal="getWhatModal" />
  </div>
</template>

<script>
import { ModelSelect } from 'vue-search-select';
import mixins from '@/components/mixins/swal';
import CartEmpty from '@/components/atoms/CartEmpty';
import CartList from '../molecules/CartList';
import ModalOrder from '@/components/molecules/ModalOrder';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
export default {
    name: 'CartCheckout',
    mixins: [mixins],
    components: {
        CartEmpty,
        ModelSelect,
        CartList,
        ModalOrder,
    },
    data() {
        return {
            cashback: false,
            note: ''
        };
    },
    beforeDestroy() {
        this.removeLocalStorageKey();
        document.getElementsByTagName("body")[0].style.overflow = "auto";
    },
    mounted() {
        window.addEventListener('beforeunload', this.removeLocalStorageKey);
        document.getElementsByTagName("body")[0].style.overflow = "hidden";
    },
    methods: {
        removeLocalStorageKey() {
            localStorage.removeItem('customer_token');
        },
        ...mapMutations('modal', ['SET_WHAT_MODAL']),
        ...mapMutations('cart', [
            'CLEAR_CART',
            'SAVE_CART_TO_MODAL',
            'GENERATE_INVOICE',
            'SET_EMAIL_MEMBER',
        ]),
        ...mapMutations('auth', ['CLEAR_CUSTOMER']),
        ...mapActions('history', ['postHistory']),
        ...mapActions('cart', ['createOrder', 'amountToPay', 'addOrderNote']),
        ...mapActions('auth', ['logoutCustomer']),
        clearCart() {
            this.CLEAR_CUSTOMER();
            this.CLEAR_CART();
        },
        setOrderProps(type) {
            this.SET_WHAT_MODAL(type);
        },
        showModal() {
            // const isCustomerTokenExist = localStorage.getItem('customer_token');
            // if (isCustomerTokenExist) {
            //   this.SET_WHAT_MODAL('Order Type');
            // } else {
            //   this.SET_WHAT_MODAL('Confirmation');
            // }
            this.addOrderNote({ note: this.note });
            this.note = '';
            this.SET_WHAT_MODAL('Order Confirmation');
        },
    },
    computed: {
        ...mapState('cart', ['carts', 'cartCheckout', 'invoice', 'orderPayload']),
        ...mapGetters('auth', ['getCustomer']),
        ...mapGetters('cart', [
            'cartTotalPrice',
            'getInvoiceRandom',
            'getDiscountAmount',
            'getDiscountType',
        ]),
        ...mapGetters('user', ['getDetailUser']),
        ...mapGetters('modal', ['getWhatModal']),
        ...mapGetters('cart', ['getOrderType']),
        ...mapGetters("cart", ["countTotalCart"]),
        getTotalAmount() {
            let amount = this.cartTotalPrice;
            if (this.getCustomer) {
                if (this.cashback) {
                    this.orderPayload.cashback = true;
                    amount = amount - this.getCustomer.cashback_points;
                } else {
                    this.orderPayload.cashback = false;
                }
            }

            if (this.getDiscountAmount > 0) {
                if (this.getDiscountType == 'flat') {
                    amount = amount - this.getDiscountAmount;
                } else {
                    amount = amount - (amount / 100) * this.getDiscountAmount;
                }
            }
            let total = amount > 0 ? amount : 0.0;
            let gst = 9 / 100;

            total = parseFloat(total) + total * gst;

            this.amountToPay({ total });
            return total;
        },

        getGstAmount() {
            let amount = this.cartTotalPrice;
            if (this.getCustomer) {
                if (this.cashback) {
                    this.orderPayload.cashback = true;
                    amount = amount - this.getCustomer.cashback_points;
                } else {
                    this.orderPayload.cashback = false;
                }
            }

            if (this.getDiscountAmount > 0) {
                if (this.getDiscountType == 'flat') {
                    amount = amount - this.getDiscountAmount;
                } else {
                    amount = amount - (amount / 100) * this.getDiscountAmount;
                }
            }
            let total = amount > 0 ? amount : 0.0;
            let gst = 9 / 100;

            return total * gst;
        },
    },
};
</script>

<style scoped>
.cart-ic-res {
    position: absolute !important;
    top: -2px;
    right: 6px;
}

.main-cart {
    padding: 0 20px;
}

.clickable {
    cursor: pointer;
}

.cart-details {
    /* width: 30% */
}

.order-note {
    background-color: #f4eade;
    border: 1px solid #0d3c4e;
    color: black;
}

.place-order-btn {
    background-color: #0d3c4e;
}

.order-type {
    padding: 12px;
    /* width:inherit; */
    border: 1px solid #0d3c4e;
    background-color: #f4eade;
    border-radius: 3px;
}

.cancel-order-btn {
    background-color: #f4eade;
    border: 1px solid #0d3c4e;
    color: #0d3c4e;
}

.discount-btn {
    background-color: #f4eade;
    color: black;
}

.discount-btn:hover {
    background-color: #0d3c4e;
    color: white;
}

@media screen and (max-width:1024px) {
    .main-cart {
        width: max-content;
        padding: 0;
    }
    .order-note {
        width: max-content;
        /* padding-left:2px; */
    }
    .order-type {
        width: max-content;
    }
    .cart-details {
        width: 21rem;
    }
}

@media screen and (max-width:768px) {
    .main-cart {
        width: max-content;
        padding: 0;
    }
    .order-note {
        width: max-content;
        padding-left: 2px;
    }
    .cart-details {
        width: 13rem;
    }
    .row {
        width: 13rem;
    }
}
</style>
