<template>
  <div>
    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop :title="whatModal"
      :size="'md'" centered @hide="hideModal('')" v-if="getWhatModal == 'Order Confirmation'">
      <div class="row">
        <div class="mx-auto">Do you want to place an order?</div>
      </div>
      <div class="container text-center mt-2">
        <div class="row">
          <div class="col">
            <div class="mx-auto">
              <button class="btn btn-primary mx-2" @click="confirm()" v-b-modal.modal-primary>
                CONFIRM
              </button>
              <button class="btn btn-secondary mx-2" @click="cancel()">CLOSE</button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop :title="whatModal"
      :size="'md'" centered @hide="hideModal('')" v-if="getWhatModal == 'Confirmation'">
      <div class="row">
        <div class="mx-auto">How would you like to proceed with the checkout ?</div>
      </div>
      <div class="container text-center mt-2">
        <div class="row">
          <div class="col">
            <div class="mx-auto">
              <button class="btn btn-primary mx-2" @click="showLoginModal('Login')" v-b-modal.modal-primary>
                Login
              </button>
              <button class="btn btn-secondary mx-2" @click="place('guest')">
                Place order as guest
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop :title="whatModal"
      :size="'md'" centered @hide="hideModal('')" v-if="getWhatModal == 'Apply Discount'">
      <b-container>
        <ul class="nav nav-pills mb-3 justify-content-center bg-light p-2" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link bg-primary mr-2" :class="getDiscountType == 'flat' ? 'active' : ''"
              id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab"
              aria-controls="pills-home" aria-selected="true" @click="setDiscountType('flat')">
              FLAT
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link bg-primary" id="pills-profile-tab"
              :class="getDiscountType == 'percentage' ? 'active' : ''" data-toggle="pill" data-target="#pills-profile"
              type="button" role="tab" aria-controls="pills-profile" aria-selected="false"
              @click="setDiscountType('percentage')">
              PERCENTAGE
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <b-row class="mx-auto p-3 text-center bg-light rounded">
            <b-col cols="" class="text-center">
              <b-form-input v-model="inputValue" type="number" class="form-control"></b-form-input>
            </b-col>
          </b-row>
        </div>

        <b-row class="ml-1 py-2 rounded">
          <div class="mr-2">Deals:</div>
          <div cols="" v-for="type in discountDeals" :key="type">
            <b-badge href="#" variant="dark" class="mr-2 cursor-pointer" active-class="true" size="sm"
              @click="setOrderDeal(type)">{{ type }}</b-badge>
          </div>
        </b-row>

        <b-row class="mb-4">
          <b-col v-for="number in numbers" :key="number" cols="4" class="text-center">
            <b-button @click="appendToInput(number)" variant="light" class="btn-lg mb-2">{{
              number
            }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="4" class="text-center">
            <b-button @click="removeLastCharacter" variant="dark" class="btn-lg mb-2"><b-icon
                icon="backspace-fill" /></b-button>
          </b-col>
          <b-col cols="4" class="text-center">
            <b-button @click="appendToInput(0)" variant="light" class="btn-lg mb-2">0</b-button>
          </b-col>
          <b-col cols="4" class="text-center">
            <b-button @click="setDiscountAmount" variant="success" class="btn-lg mb-2"><b-icon
                icon="arrow-right-circle-fill" /></b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop
      :title="'Select order type'" :size="'lg'" centered @hide="hideModal('')" v-if="getWhatModal == 'Order Type'">
      <!-- <div class="row">
        <div class="mx-auto">Select order type:</div>
      </div> -->
      <div class="container mx-atuo text-center mt-1">
        <div class="row">
          <div class="col-md-3 payment-method mb-1 cursor" @click="setOrderType('dine-in')">
            <img :src="require(`@/assets/images/dine-in.png`)" alt="dine-in" width="120" height="100" />
          </div>
          <div class="col-md-4 mb-1 cursor" @click="setOrderType('take-away')">
            <img :src="require(`@/assets/images/takeaway.png`)" alt="take-away" width="120" height="100" />
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop
      :title="'Select payment type'" :size="'md'" centered @hide="hideModal('')" v-if="getWhatModal == 'Payment Type'">
      <!-- <div class="row">
        <div class="mx-auto">Select payment type:</div>
      </div> -->
      <div class="container text-center mt-1">
        <div class="row justify-content-center">
          <div class="col-md-4 mb-1 cursor" @click="setPaymentType('fiserv')">
            <img :src="require(`@/assets/images/fiserv.png`)" alt="take-away" width="120" height="100" />
          </div>
          <div class="col-md-4 mb-1 cursor bg-warning rounded" @click="setPaymentType('cash')">
            <img :src="require(`@/assets/images/cash.png`)" alt="take-away" width="120" height="100" />
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop
      :title="'Payment confirmation'" :size="'md'" centered @hide="hideModal('')"
      v-if="getWhatModal == 'paymentConfirmation'">
      <div>
        <div v-if="loading" class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <p class="h4">Payment status: {{ paymentStatus }}</p>
      </div>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop :title="whatModal"
      :size="'lg'" centered @hide="hideModal('')" v-if="getWhatModal == 'Login'">
      <LoginModal />
    </b-modal>
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
import LoginModal from './LoginModal.vue';
import { getConfiguration } from '../../apis/User';

export default {
  name: 'ModalOrder',
  props: ['whatModal'],
  mixins: [mixins],
  components: {
    LoginModal,
  },
  data() {
    return {
      inputValue: '',
      discount: '',
      configSettings: {
        ip: null,
        port: null,
      },
      loading: false,
      paymentStatus: '',
    };
  },

  mounted() {
    this.configuration();
  },
  computed: {
    numbers() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9];
    },
    discountDeals() {
      return ['entertainment', 'new-user', 'xyz-deal'];
    },
    discountTypes() {
      return ['flat', 'percentage'];
    },
    ...mapGetters('category', ['categoryProducts', 'productAddons']),
    ...mapGetters('modal', ['getWhatModal']),
    ...mapGetters('user', ['getDetailUser', 'getConfiguration']),
    ...mapState('cart', ['carts', 'orderPayload']),
    ...mapGetters('cart', [
      'cartTotalPrice',
      'getOrderType',
      'getPaymentType',
      'getDiscountAmount',
      'getDiscountType',
      'getAmountToPay',
    ]),
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    configuration() {
      const branchId = localStorage.getItem('branchId');
      const token = localStorage.getItem('token');

      const requestData = {
        branchId: branchId,
        token: token,
      };
      getConfiguration(requestData)
        .then((response) => {
          if (response.status) {
            this.configSettings.ip = response.data.data.ip;
            this.configSettings.port = response.data.data.port;
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },
    ...mapMutations('category', ['SET_PRODUCT_ADDONS']),
    ...mapMutations('modal', ['SET_WHAT_MODAL', 'HIDE_MODAL']),
    ...mapActions('cart', [
      'createOrder',
      'orderType',
      'paymentType',
      'discountType',
      'orderDeal',
      'discountAmount',
    ]),
    ...mapActions('auth', ['logoutCustomer']),
    ...mapMutations('cart', ['SAVE_CART_TO_MODAL']),
    appendToInput(number) {
      this.inputValue += number;
    },
    removeLastCharacter() {
      this.inputValue = this.inputValue.slice(0, -1);
    },
    hideModal(val) {
      this.SET_WHAT_MODAL(val);
    },

    showModal(val) {
      this.SET_WHAT_MODAL(val);
    },

    showLoginModal(val) {
      this.SET_WHAT_MODAL(val);
    },

    place() {
      this.SET_WHAT_MODAL('Order Type');
    },

    placeOrder(type) {
      this.SAVE_CART_TO_MODAL({
        products: this.carts,
        price: this.cartTotalPrice,
      });
      const customerToken = localStorage.getItem('customer_token');
      const branchId = this.getDetailUser.data.branch.id;

      const requestData = {
        config: this.configSettings,
        branchId: branchId,
        payload: this.orderPayload,
        token: type == 'guest' ? null : customerToken,
      };

      this.createOrder(requestData);
      // this.SET_WHAT_MODAL('Print');
    },

    setOrderType(type) {
      this.orderType({ type });
      this.HIDE_MODAL('ORDER TYPE');
      // this.showModal('Payment Type');
    },

    setPaymentType(type) {
      this.paymentType({ type });
      this.HIDE_MODAL('Payment Type');

      // if (type == 'fiserv') {
      //   this.SET_WHAT_MODAL('paymentConfirmation');
      //   this.placeOrder(type);
      // } else {
      //   this.placeOrder(type);
      // }

      // const customerToken = localStorage.getItem('customer_token');
      // if (customerToken) {
      //   this.logoutCustomer();
      // }
    },

    setDiscountType(type) {
      this.discountType({ type });
    },

    setOrderDeal(type) {
      this.orderDeal({ type });
    },

    setDiscountAmount() {
      const amount = this.inputValue;
      this.discountAmount({ amount });
      this.inputValue = '';
      this.HIDE_MODAL('APPLY DISCOUNT');
    },

    cancel() {
      this.SET_WHAT_MODAL('');
    },
    confirm() {
      const customerToken = localStorage.getItem('customer_token');
      this.placeOrder(customerToken ? customerToken : 'guest');
      if (customerToken) {
        this.logoutCustomer();
      }

      this.HIDE_MODAL('Order Confirmation');;
    },
  },
};
</script>

<style scoped>
.btn-lg {
  font-size: 1.5rem;
  width: 100%;
  margin: 0, 10;
}

.form-control-lg {
  font-size: 1.5rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.mb-2 {
  margin-bottom: 10px;
}

.addon-description {
  font-weight: bold;
}

.addon-max-selection {
  font-size: 0.8em;
}

.addon-item {
  transition: box-shadow 0.3s ease;
}

.addon-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.selected {
  background-color: #6de28a;
  color: #fff;
}

.disabled {
  background-color: #bfbcbc;
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}

.addons-list {
  list-style-type: none;
  padding-left: 0;
}

.addon-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.addon-price {
  font-size: 0.875rem;
  /* Adjust font size */
}
@media screen and (max-width:768px) {
.row{
   display: flex;
   justify-content: center;
   gap:10px;
   width:30rem;
}
}
@media screen and (min-width:768px) {
.row{
   display: flex;
   justify-content: center;

}
}
</style>
