<template>
  <div class="chart-empty">
    <!-- <img :src="require(`@/assets/images/food-and-restaurant.png`)" alt="emptycart" /> -->
    <div class="py-2  mb-5 circle" >
    </div>
    <h5>Your cart is empty</h5>
    <p>Please add some items from the menu</p>
  </div>
</template>

<script>
export default {
  name: 'CartEmpty'
}
</script>

<style scoped>
.chart-empty{
  flex-direction: column;
}
.circle{
  background-color:#0f3442; width:50px; height:50px; border-radius:50%;
  margin-left: auto;
  margin-right: auto;
}
h5{
  font-weight: 600;
}
p{
  color:rgb(0, 0, 0);
}
@media screen and (max-width:768px) {
h5,p{
width:80%;
}
.circle{
  margin-right:130px;
}

}
</style>
