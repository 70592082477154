<template>
  <div class="d-flex justify-content-center align-items-center vh-100" style="background-color: #0d3c4e;">
    <div class="card shadow py-5" style="border-radius: 1rem; background-color:#F4EADE;">
      <div class="text-center mb-4">
        <img :src="logoUrl" alt="Logo" class="mb-2 mt-1 harveys-logo" />
        <p class="mb-2" style="font-size: 25px;">Desk (login)</p>
      </div>
      <form @submit.prevent="login" class="text-center px-5" >
        <div class="mb-3 text-left">
          <label for="email" class="form-label " style="font-size: 22px;">Email</label>
          <input
                  type="email"
                  class="form-control py-2 w-100"
                  name="email"
                  placeholder="xyz@gmail.com"
                  required
                  @keyup="checkEmail"
                  :class="[email.length > 2 ? statusEmail : '']"
                  v-model="email"
                  style="font-size: 18px;background-color: #cad8db !important;"
          />
          <div class="invalid-feedback">{{ messageEmail }}</div>
        </div>
        <div class="mb-4 text-left">
          <label for="password" class="form-label" style="font-size: 22px;">Password</label>
          <input
          type="password"
                  class="form-control py-2 w-100"
                  name="password"
                  placeholder="Password"
                  required
                  v-model="password"
                  style="font-size: 18px;background-color: #cad8db !important;"
          />
        </div>
        <button type="submit" class=" form-control  btn w-100 text-white login-btn" style="font-size: 17px;" :isLoading="getLoading">Login</button>
      </form>
    </div>
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      logoUrl: require('@/assets/images/harveysLogo.jpeg'), // Adjust the path to match your project structure
      email: '',
      password: '',
      messageEmail: '',
      statusEmail: '',
    };
  },
  methods: {
      ...mapActions('auth', ['loginUser']),
      clearRedirect() {
        this.email = '';
        this.password = '';
        this.statusEmail = '';
        this.messageEmail = '';
        this.$router.push({ name: 'Home' });
      },
      checkEmail() {
        // eslint-disable-next-line no-useless-escape
        const isTrue = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
        if (isTrue) {
          this.statusEmail = '';
        } else {
          this.statusEmail = 'is-invalid';
          this.messageEmail = 'Invalid email';
        }
      },
      login() {
        const dataLogin = {
          email: this.email,
          password: this.password,
        };
        this.loginUser(dataLogin)
          .then((response) => {
            this.$router.push({ name: 'Home' });
            this.toastSuccess(response.message);
          })
          .catch((error) => {
            this.toastError(error.message);
            this.password = '';
          });
      },
    },
    computed: {
      lihatStatus() {
        return this.$store.state.auth.statusLogin;
      },
      ...mapGetters(['getLoading']),
    },
};
</script>

<style scoped>
.harveys-logo{
  width:14rem;
}
.card {
  background-color: #f5e8d4;
  border-radius: 1rem;
}
.login-btn{
  background-color: #0F3442;
}
@media only screen and (min-width: 728px) {
  .card{
    width:50%;
  }
}
@media only screen and (min-width: 1024px) {
  .card{
    width:23%;
  }
}

</style>