<template>
  <div class="sidebar py-3 px-2" style="background-color: #F4EADE;">
    <div class="sidebar-sub mx-auto text-center">
      <div class="d-flex flex-column">
      <router-link title="Home" :to="{ name: 'Home' }" class="sidebar-item" v-active="currentRouteName">
        <img :src="require(`@/assets/images/food.png`)" alt="food"  /> <br>
        <span>Home</span>
      </router-link>

      <router-link v-if="roleId === 1" title="Product" :to="{ name: 'Product' }" class="sidebar-item"
        v-active="currentRouteName">
        <b-icon icon="box" font-scale="1.5"></b-icon>
        
      </router-link>
      <router-link v-if="roleId === 1" title="Category" :to="{ name: 'Category' }" class="sidebar-item"
        v-active="currentRouteName">
        <img :src="require(`@/assets/images/category.png`)" alt="history" />
      </router-link>
      <router-link v-if="roleId === 1" title="Users" :to="{ name: 'Users' }" class="sidebar-item"
        v-active="currentRouteName">
        <img :src="require(`@/assets/images/users.png`)" alt="users" />
      </router-link>
      <router-link title="Profile" :to="{ name: 'Profile' }" class="sidebar-item" v-active="currentRouteName">
        <img :src="require(`@/assets/images/users.png`)" alt="history" /> <br>
        <span>Profile</span>
      </router-link>

      <router-link title="Pending Orders" :to="{ name: 'Pending' }" class="sidebar-item" v-active="currentRouteName">
        <img :src="require(`@/assets/images/pending-orders.png`)" alt="pending orders"> <br>
        <span>Pending <br> Orders</span>
      </router-link>

      <router-link title="Completed Orders" :to="{ name: 'History' }" class="sidebar-item bg-black"
        v-active="currentRouteName">
        <img :src="require(`@/assets/images/Order.png`)" alt="completed orders" /> <br>
        <span class="">Completed Orders</span>
      </router-link>

      <router-link title="Cancelled Orders" :to="{ name: 'Cancelled' }" class="sidebar-item bg-black"
        v-active="currentRouteName">
        <img :src="require(`@/assets/images/Error.png`)" alt="cancelled orders" /> <br>
        <span>Cancelled <br> Orders</span>
      </router-link>

      <router-link title="Stock Management" :to="{ name: 'Stock' }" class="sidebar-item" v-active="currentRouteName">
        <img :src="require(`@/assets/images/stock.png`)" alt="stock management" /> <br>
        <span>Stock Management</span>
      </router-link>
    </div>
    <div>

    
      <ButtonLogout classBtn="btn m-0 p-0 sidebar-item">
        <img :src="require(`@/assets/images/logout.png`)" alt="stock management" /> <br>
        <span>Logout</span>
      </ButtonLogout>
    </div>
    </div>
    <b-modal id="modal-logout" title="Logout">
      <p class="my-4">Hello from modal!</p>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ButtonLogout from '@/components/molecules/ButtonLogout';
export default {
  name: 'Sidebar',
  directives: {
    active: {
      inserted: (el, binding) => {
        if (el.title === binding.value) {
          el.classList.add('active');
        }
      },
    },
  },
  components: {
    ButtonLogout,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapState('auth', ['roleId']),
  },
};
</script>

<style scoped>
.sidebar-item {
  animation: 1s ease-in-out;
}
.sidebar{
min-height:100vh;
min-width:min-content;
}
.sidebar-sub{
display: flex;
justify-content: space-between;
flex-direction: column;
gap:10rem;
}
.sidebar-item.active {
  animation: 1s ease-in-out;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.sidebar-item.active:nth-child(1) {
  margin-top: 10px;
}
span{
  color:#0f4332;
  cursor: pointer;
  font-weight:800;
  font-size:15px;
}
#modal-logout{
  background-color: #f4eade;
}
</style>
