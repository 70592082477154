<template>
  <div v-if="detailProduct.display">
    <div class="categories-container">
      <div @click="$emit('show-modal', 'product')" @click.prevent="SET_CATEGORY_PRODUCTS(detailProduct)"
        v-b-modal.modal-primary class="col-sm border cursor-pointer removeDefaultPadding categories" style="background-color: #F4EADE; box-shadow:3px 4px 5px rgba(0, 0, 0, 0.15);"
        :class="{ active: checkActive.includes(detailProduct.id) }">
        <img :src="detailProduct.preview_url" :alt="detailProduct.name" class="cat-img img-fluid" />
        <p class="text-sm font-bold cat-text text-gray-700 py-1 text-center">
          {{ detailProduct.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ModalProduct from "./ModalProduct";
import { mapMutations, mapGetters } from "vuex";
export default {
  name: "CategoryItem",
  props: ["detailProduct", "checkActive"],
  methods: {
    ...mapMutations("category", ["SET_CATEGORY_PRODUCTS"]),
  },
  components: {
    ModalProduct,
  },
  computed: {
    ...mapGetters("category", ["categoryProducts"]),
  },
};
</script>

<style scoped>
.cursor-pointer:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.cat-img{
  height: 150px;
  width: 150px;
}
.removeDefaultPadding {
  padding: 3px;
}
/* .categories{
  border-radius:10px;
  margin:2px;  
} */
.cat-text{
  font-size:medium;
  font-weight:600;
  color:#0f3442;
}
@media screen and (max-width:768px) {
  .categories-container{
    margin:4px;
  }
  .categories{
  border-radius:10px;
  margin:2px;  
}
.cat-img{
  width:120px;
  height:120px;
  display: block;
}

}
@media screen and (min-width:1024px) {
  .categories{
    border-radius:10px;
  }
  .categories-container{
    margin:10px;
    padding-left:12px;
  }
 

}
</style>
