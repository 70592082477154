<template>
    <b-modal class="select-addons" v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop
      :title="'Select Addons'" :size="'xl'" centered @hide="hideModal('')" v-if="getWhatModal == 'addons'">
      
      <div v-show="Object.keys(selectedAddonModifier).length == 0 " v-if="selectedProduct && selectedProduct.addons">
        <b-container fluid>
          <b-row>
            <b-col v-for="addon in selectedProduct.addons" :key="addon.id" :lg="12" :md="8" :sm="12" class="mb-4">
              <b-card class="card">
                <b-card-header style="background-color:#F4EADE; border-radius:14px; border: none;">
                  <span class="addon-description">{{ addon.modifier && addon.modifier.description }}</span>
                  <span class="addon-max-selection float-right badge badge-info">Max selection: {{ addon.max_selection
                  }}</span>
                  <span class="badge required-badge ml-1" v-show="addon.required">Required</span>
                </b-card-header>
                <b-container fluid>
                  <b-row class="mt-3">
                    <b-col v-for="product in addon.addon_products" :key="product.id"  :class="[
                      'p-2',
                      'cursor-pointer',
                      'addon-item',
                      {
                        selected: isProductSelected(addon, product),
                      },
                    ]" :lg="3" :md="6" :sm="12" @click="toggleProductSelection(addon, product)">
                      <h6 class="text-center pt-1">{{ product.product && product.product.name }}</h6>
                      <p class="text-center">${{ product.product && product.product.sale_price }}</p>
  
                      <div
                        v-if="product.product && product.product.stock && product.product.stock.tags && product.product.stock.tags.length > 0">
                        <img v-for="(tag, index) in JSON.parse(product.product.stock.tags)" :key="index" :src="tag"
                          :alt="'Tag ' + (index + 1)" :class="{ 'left-tag': index == 0, 'right-tag': index == 1 }"
                          width="30" />
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
        <b-button :disabled="isContinueButtonDisabled" @click="showModal('')" v-b-modal.modal-primary
          class="mt-3 float-right" style="background-color: #0f3442;">
          Continue
        </b-button>
      </div>
      <!-- Addon modifier  -->
      <div v-show="Object.keys(selectedAddonModifier).length > 0">
        <b-container fluid class="">
          <b-row>
            <b-col v-for="addon in selectedAddonModifier.addons" :key="addon.id" :lg="12" :md="9" :sm="12" class="mb-4">
              <b-card>
                <b-card-header class="description-header" style="border-radius:14px;">
                  <span class="addon-description">{{ addon.modifier && addon.modifier.description }}</span>
                  <span class="addon-max-selection float-right badge badge-info">Max selection: {{ addon.max_selection
                  }}</span>
                  <span class="badge required-badge ml-1" v-show="addon.required">Required</span>
                </b-card-header>
                <b-container fluid>
                  <b-row class="mt-3">
                    <b-col v-for="product in addon.addon_products" :key="product.id" :class="[
                      'p-2',
                      'cursor-pointer',
                      'addon-item',
                      {
                        selected: isProductSelected(addon, product),
                      },
                    ]" :lg="3" :md="6" :sm="12" @click="toggleProductSelection(addon, product)">
                      <h6 class="text-center">{{ product.product && product.product.name }}</h6>
                      <p class="text-center">${{ product.product && product.product.sale_price }}</p>
  
                      <div
                        v-if="product.product && product.product.stock && product.product.stock.tags && product.product.stock.tags.length > 0">
                        <img v-for="(tag, index) in JSON.parse(product.product.stock.tags)" :key="index" :src="tag"
                          :alt="'Tag ' + (index + 1)" :class="{ 'left-tag': index == 0, 'right-tag': index == 1 }"
                          width="30" />
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
        <b-button :disabled="isContinueButtonDisabled" @click="clearSelectedAddons()" v-b-modal.modal-primary
          class="mt-3 float-right" style="background-color:#0f3442;">
          Continue
        </b-button>
      </div>

    </b-modal>
    
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'ModalOrder',
  props: ['whatModal', 'selectedProduct'],
  mixins: [mixins],
  data() {
    return {
      selectedAddons: [],
      completeAddonsObj: [],
      selectedAddonModifier: {}
    };
  },
  computed: {
    ...mapGetters('modal', ['getWhatModal']),

    isContinueButtonDisabled() {
      for (const addon of this.selectedProduct.addons) {
        if (addon.required) {
          const selectedProductsInRow = this.selectedAddons.filter(
            (selectedProduct) => selectedProduct.addon_id === addon.id
          );
          if (selectedProductsInRow.length === 0) {
            return true;
          }
        }
      }

      for (const product of this.completeAddonsObj) {
        if (product.product && product.product.stock && (!product.product.stock.is_enabled || product.product.stock.available_stock <= 0)) {
          return true;
        }
      }

      return false;
    }

  },
  methods: {
    ...mapMutations('category', ['SET_PRODUCT_ADDONS']),
    ...mapMutations('modal', ['SET_WHAT_MODAL', 'HIDE_MODAL']),
    ...mapMutations('cart', ['ADD_TO_CART']),

    hideModal(val) {
      this.SET_WHAT_MODAL(val);
      this.selectedAddons = [];
      this.completeAddonsObj = [];
    },

    showModal(val) {
      this.SET_WHAT_MODAL(val);
      this.submitProduct();
    }, 

    clearSelectedAddons() {
      this.selectedAddonModifier = {}
    },
    
    submitProduct() {
      this.ADD_TO_CART({
        product: {
          id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          sale_price: this.selectedProduct.sale_price,
          preview_url: this.selectedProduct.preview_url,
        },
        addons_id: this.selectedAddons,
        addons: this.completeAddonsObj,
        qty: 1,
        productWithAddons: this.selectedProduct,
      });

      this.selectedAddons = [];
      this.completeAddonsObj = [];
      this.SET_PRODUCT_ADDONS(null);
    },

    toggleProductSelection(addon, product) {
      const isAlreadySelected = this.isProductSelected(addon, product);
      const isRequiredAndSingleSelection = addon.required && addon.max_selection === 1;
      const isNotRequiredAndSingleSelection = !addon.required && addon.max_selection === 1;
      const isRequiredAndMultipleSelection = addon.required && addon.max_selection > 1;

      if (isRequiredAndSingleSelection) {
        // If addon product is required and only one selection is allowed,
        // switch to the new product if already selected changed
        if (!isAlreadySelected) {
          this.switchSelection(addon, product);
        }
      } else if (isNotRequiredAndSingleSelection) {
        // If addon product is not required and only one selection is allowed,
        // switch to the new product if already selected changed
        if (this.getSelectedCount(addon) > 1 || !isAlreadySelected) {
          this.switchSelection(addon, product);
        } else {style="background-color: #F4EADE;"
          this.unselectProduct(addon, product);
        }
      } else if (isRequiredAndMultipleSelection) {
        // If addon requires multiple selections and there's only one selection,
        // do nothing to maintain at least one selection
        if (this.getSelectedCount(addon) > 1 || !isAlreadySelected) {
          this.toggleSelection(addon, product);
        }
      } else {
        // For other cases, toggle the selection based on the current state
        this.toggleSelection(addon, product);
      }

      if(product.selected && product.product.addons && product.product.addons.length > 0) {
        this.selectedAddonModifier = product.product
      }
    },

    toggleSelection(addon, product) {
      if (this.isProductSelected(addon, product)) {
        this.unselectProduct(addon, product);
      } else {
        this.selectProduct(addon, product);
      }
    },

    switchSelection(addon, product) {
      // Switch to the new product by unselecting the previous one and selecting the new one
      const selectedProducts = this.selectedAddons.filter(
        (selectedProduct) => selectedProduct.addon_id === addon.id
      );

      if (selectedProducts.length > 0) {
        const previousProduct = selectedProducts[0];
        this.unselectProduct(addon, { product: { id: previousProduct.addon_product_id } });
      }

      this.selectProduct(addon, product);
    },

    getSelectedCount(addon) {
      return this.selectedAddons.filter((selectedProduct) => selectedProduct.addon_id === addon.id)
        .length;
    },

    isProductSelected(addon, product) {
      return this.selectedAddons.some(
        (selectedProduct) =>
          selectedProduct.addon_id === addon.id &&
          selectedProduct.addon_product_id === product.product.id
      );
    },

    selectProduct(addon, product) {
      this.selectedAddons.push({ addon_id: addon.id, addon_product_id: product.product.id });
      this.completeAddonsObj.push(product);
      product.selected = true;
    },

    unselectProduct(addon, product) {
      const addonProductIdToRemove = product.product.id;

      this.selectedAddons = this.selectedAddons.filter(
        (selectedProduct) =>
          !(
            selectedProduct.addon_id === addon.id &&
            selectedProduct.addon_product_id === addonProductIdToRemove
          )
      );

      this.completeAddonsObj = this.completeAddonsObj.filter(
        (completeProduct) =>
          !(
            completeProduct.product_modifier_id === addon.id &&
            completeProduct.product_id === addonProductIdToRemove
          )
      );

      if (product.product.addons && product.product.addons.length > 0) {
        for(let i = 0; i < product.product.addons.length; i++) {
          for(let j = 0; j < product.product.addons[i].addon_products.length; j++) {
           this.unselectProduct(product.product.addons[i], product.product.addons[i].addon_products[j])
          }
        }
      }

      product.selected = false;
    },

    isProductDisabled(addon, product) {
      const selectedCount = this.selectedAddons.filter(
        (selectedProduct) => selectedProduct.addon_id === addon.id
      ).length;
      return selectedCount >= addon.max_selection && !this.isProductSelected(addon, product);
    },
  },
};
</script>

<style scoped>
.select-addons{
  border:none;
}
.btn-lg {
  font-size: 1.5rem;
  width: 100%;
  margin: 0, 10;
}

.form-control-lg {
  font-size: 1.5rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.mb-2 {
  margin-bottom: 10px;
}

.addon-description {
  font-weight: bold;
}
.description-header{
  background-color:#F4EADE;
  border: none;
}
.addon-max-selection {
  font-size: 0.8em;
  background-color: rgb(245, 133, 113);
  padding:7px;
}

.addon-item {
  transition: box-shadow 0.3s ease;
  background-color: #F4EADE ;
  color: #000;
  border-radius:14px;
  margin:6px;

}

.addon-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.selected {
  background-color:rgb(245, 133, 113);
  color: #000;
}

.disabled {
  background-color: #bfbcbc;
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}

.left-tag {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin-left: 0px !important;
  margin-top: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.right-tag {
  width: 25px;
  height: 25px;
  border-radius: 0;
  margin-left: 0px !important;
  margin-top: 0;
  position: absolute;
  top: 0;
  right: 0;
}
.required-badge{
  background-color: #60D68B;
  padding:6px;
  border-radius:7px;
  font-size:13px;
  color: white;
}
.card{
  border: 2px solid #bebebe;
  border-radius:20px
}
h6{
  font-weight:500;
}
@media screen and (max-width:768px) {
 
}
</style>
