<template>
  <div>
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Code</th>
          <th scope="col">Type</th>
          <th scope="col">Status</th>
          <th scope="col">Platform</th>
          <th scope="col">Payment</th>
          <th scope="col">Discount</th>
          <th scope="col">Discount Type</th>
          <th scope="col">Total</th>
          <th scope="col">Sub Total</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody v-if="servedOrders.length > 0">
        <tr v-for="(order, index) in servedOrders" :key="index">
          <th scope="row">{{ index + 1 }}</th>
          <td>{{ order.code }}</td>
          <td>{{ order.type }}</td>
          <td>{{ order.status }}</td>
          <td>{{ order.platform }}</td>
          <td>{{ order.payment }}</td>
          <td>{{ order.discount }}</td>
          <td>{{ order.discount_type ? order.discount_type : 'none' }}</td>
          <td>{{ order.total }}</td>
          <td>{{ order.sub_total }}</td>
          <td>
            <b-button @click="showModal('Print', order.id)" v-b-modal.modal-primary variant="info" class="mr-2">{{
              order.reprint > 0 ? 'Reprint' : 'Print' }}</b-button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="11">No orders found</td>
        </tr>
      </tbody>
    </table>

    <ReportModal :whatModal="getWhatModal" />
    <!-- <PrintOrder /> -->
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import PrintOrder from './PrintOrder.vue';
import ReportModal from './ReportModal.vue';
import { getOrder, PrintCounter } from '../../apis/Order';

export default {
  name: 'TableHistory',
  mixins: [mixins],
  components: {
    ReportModal,
    PrintOrder,
  },
  data() {
    return {
      orderDetails: null
    }
  },
  mounted() {
    this.fetchServedOrders();
  },
  computed: {
    ...mapGetters('history', ['getServedOrders']),
    ...mapGetters('modal', ['getWhatModal']),
    ...mapGetters('user', ['getDetailUser']),
    servedOrders() {
      return this.getServedOrders;
    },
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL']),
    ...mapActions('history', ['fetchServedOrders']),
    showModal(val, id) {
      const branchId = this.getDetailUser.data.branch.id;
      const token = localStorage.getItem('token');
      const requestData = {
        branchId: branchId,
        id: id,
        token: token,
      };

      getOrder(requestData)
        .then((response) => {
          if (response.status) {
            this.orderDetails = response.data.data;
            this.generateReceipt();
            this.printReceipt(requestData);
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });

      // this.SET_WHAT_MODAL(val);
    },

    printReceipt(requestData) {
      PrintCounter(requestData)
        .then((response) => {
          if (response.status) {
            this.fetchServedOrders();
            return true;
          } else {
            console.log('error', response.message);
          }
        })
        .catch((error) => {
          console.log('Error', error);
        });
    },

    generateReceipt() {
      const receiptWindow = window.open('', 'Receipt', 'width=600,height=400');
      receiptWindow.document.write(`
      <html>
        <head>
          <title>Receipt</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              font-size: 11px;
            }
            .receipt {
              max-width: 80mm;
              margin: 0 auto;
              padding: 10px;
              border: 1px solid #ddd;
            }
            .receipt-header, .receipt-footer {
              text-align: center;
            }
            .receipt-header h2, .receipt-footer p {
              margin: 5px 0;
            }
            .receipt-header h2 {
              font-size: 18px;
            }
            .table {
              width: 100%;
              border-collapse: collapse;
            }
            .table th, .table td {
              padding: 0.3rem;
              border-top: none;
              text-align: left;
            }
            .table th {
              border-bottom: 1px solid #ddd;
              font-size: 12px;
            }
            .table td {
              font-size: 11px;
            }
            .receipt-info p, .receipt-summary p {
              display: flex;
              justify-content: space-between;
              margin: 5px 0;
            }
            .dotted-line {
              border-bottom: 1px dotted #ddd;
              margin: 10px 0;
            }
            .pl-4 {
              padding-left: 1.5rem;
            }
            .addon {
              margin-top: 5px;
              margin-bottom: 5px;
            }
          </style>
        </head>
        <body>
          <div class="receipt">
            <div class="receipt-header">
              <p style="text-align:right; color: white;">
                <span style="text-align:right; background-color: black; font-weight:400; color:white; padding-right: 10px; padding-left:10px; padding-top: 5px padding-bottom: 5px;">${this.orderDetails.reprint > 0 ? 'Reprint' : ''}
                </p>
              <h2>Harveys</h2>
              <p>77 Robinson Road, 01-04<br>Singapore 068896</p>
              <p>Email: hello@harveys.sg</p>
              <div class="dotted-line"></div>
            </div>
            <div class="receipt-info">
              <p>Order No: <strong>${this.orderDetails.code}</strong> <span>${this.orderDetails.type === 'dine-in' ? 'DINE - IN' : 'TAKE AWAY'}</span></p>
              <p>Bill No: <strong>${this.orderDetails.code}</strong> <span>${this.orderDetails.order_date}</span></p>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th class="text-right">Price</th>
                  <th class="text-center">Qty</th>
                  <th class="text-right">Total</th>
                </tr>
              </thead>
              <tbody>
                ${this.orderDetails.orderProducts.map(order => `
                  <tr>
                    <td>${order.product_name}</td>
                    <td class="text-right">${order.unit_price}s</td>
                    <td class="text-center">${order.quantity}</td>
                    <td class="text-right">${(order.unit_price * order.quantity).toFixed(2)}</td>
                  </tr>
                  ${order.addons.length > 0 ? order.addons.map(addon => `
                    <tr class="addon">
                      <td class="pl-4">&nbsp;&nbsp;&nbsp;&nbsp;${addon.product_name}</td>
                      <td class="text-right">${addon.unit_price}</td>
                      <td class="text-center">${addon.quantity}</td>
                      <td class="text-right">${(addon.unit_price * addon.quantity).toFixed(2)}</td>
                    </tr>
                  `).join('') : ''}
                `).join('')}
              </tbody>
            </table>
            <div class="dotted-line"></div>
            <div class="receipt-summary">
              <p>
                <span>Sub Total:</span>
                <span>$ ${this.orderDetails.sub_total}</span>
              </p>
              <p>
                <span>Discount:</span>
                <span>$ ${this.orderDetails.discount}</span>
              </p>
              <p>
                <span>GST(9%):</span>
                <span>$ ${this.orderDetails.gst}</span>
              </p>
              <p>
                <strong>Grand Total:</strong>
                <strong>$ ${this.orderDetails.total}</strong>
              </p>
            </div>
            <div class="dotted-line"></div>
            <div class="receipt-footer">
              <p>Thank you and see you agains</p>
            </div>
          </div>
        </body>
      </html>
    `);
      receiptWindow.print();
      receiptWindow.document.close();


      this.orderDetails = null;
    }
  }
};
</script>
