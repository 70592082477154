var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getWhatModal === 'edit_addons_' + _vm.uniqueEditingModal)?_c('b-modal',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-xl",modifiers:{"modal-xl":true}}],ref:"modal-primary",attrs:{"id":"modal-primary","hide-footer":"","no-close-on-backdrop":"","title":'Edit Addons',"size":'xl',"centered":""},on:{"hide":function($event){return _vm.hideModal('')}}},[(_vm.selectedProduct && _vm.selectedProduct.addons)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.selectedAddonModifier).length == 0 ),expression:"Object.keys(selectedAddonModifier).length == 0 "}]},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',_vm._l((_vm.selectedProduct.addons),function(addon){return _c('b-col',{key:addon.id,staticClass:"mb-4",attrs:{"lg":12,"md":12,"sm":12}},[_c('b-card',[_c('b-card-header',[_c('span',{staticClass:"addon-description"},[_vm._v(_vm._s(addon.modifier && addon.modifier.description))]),_c('span',{staticClass:"addon-max-selection float-right badge badge-info"},[_vm._v("Max selection: "+_vm._s(addon.max_selection))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(addon.required),expression:"addon.required"}],staticClass:"badge badge-primary ml-1"},[_vm._v("Required")])]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-3"},_vm._l((addon.addon_products),function(product){return _c('b-col',{key:product.id,class:[
                     'border',
                     'rounded',
                     'p-3',
                     'cursor-pointer',
                     'addon-item',
                     {
                       selected: _vm.isProductSelected(addon, product),
                     },
                   ],attrs:{"lg":3,"md":6,"sm":12},on:{"click":function($event){return _vm.toggleProductSelection(addon, product)}}},[_c('h6',{staticClass:"text-center"},[_vm._v(_vm._s(product.product && product.product.name))]),_c('p',{staticClass:"text-center"},[_vm._v("$"+_vm._s(product.product && product.product.sale_price))]),(product.product && product.product.stock && product.product.stock.tags && product.product.stock.tags.length > 0)?_c('div',_vm._l((JSON.parse(product.product.stock.tags)),function(tag,index){return _c('img',{key:index,class:{ 'left-tag': index == 0, 'right-tag': index == 1 },attrs:{"src":tag,"alt":'Tag ' + (index + 1),"width":"30"}})}),0):_vm._e()])}),1)],1)],1)],1)}),1)],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],staticClass:"mt-3 float-right",attrs:{"disabled":_vm.isContinueButtonDisabled,"variant":"primary"},on:{"click":function($event){return _vm.showModal('')}}},[_vm._v(" Continue ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.selectedAddonModifier).length > 0),expression:"Object.keys(selectedAddonModifier).length > 0"}]},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',_vm._l((_vm.selectedAddonModifier.addons),function(addon){return _c('b-col',{key:addon.id,staticClass:"mb-4",attrs:{"lg":12,"md":12,"sm":12}},[_c('b-card',[_c('b-card-header',[_c('span',{staticClass:"addon-description"},[_vm._v(_vm._s(addon.modifier && addon.modifier.description))]),_c('span',{staticClass:"addon-max-selection float-right badge badge-info"},[_vm._v("Max selection: "+_vm._s(addon.max_selection))]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(addon.required),expression:"addon.required"}],staticClass:"badge badge-primary ml-1"},[_vm._v("Required")])]),_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"mt-3"},_vm._l((addon.addon_products),function(product){return _c('b-col',{key:product.id,class:[
                     'border',
                     'rounded',
                     'p-3',
                     'cursor-pointer',
                     'addon-item',
                     {
                       selected: _vm.isProductSelected(addon, product),
                     },
                   ],attrs:{"lg":3,"md":6,"sm":12},on:{"click":function($event){return _vm.toggleProductSelection(addon, product)}}},[_c('h6',{staticClass:"text-center"},[_vm._v(_vm._s(product.product && product.product.name))]),_c('p',{staticClass:"text-center"},[_vm._v("$"+_vm._s(product.product && product.product.sale_price))]),(product.product && product.product.stock && product.product.stock.tags && product.product.stock.tags.length > 0)?_c('div',_vm._l((JSON.parse(product.product.stock.tags)),function(tag,index){return _c('img',{key:index,class:{ 'left-tag': index == 0, 'right-tag': index == 1 },attrs:{"src":tag,"alt":'Tag ' + (index + 1),"width":"30"}})}),0):_vm._e()])}),1)],1)],1)],1)}),1)],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-primary",modifiers:{"modal-primary":true}}],staticClass:"mt-3 float-right",attrs:{"disabled":_vm.isContinueButtonDisabled,"variant":"primary"},on:{"click":function($event){return _vm.clearSelectedAddons()}}},[_vm._v(" Continue ")])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }