<template>
    <b-container fluid class="nav">
        <div class="row" id="navbar">
            <div style="  background-color: #F4EADE;" class="px-0 fixed-top" :class="
                  currentRouteName === 'Home' && roleId !== 3
                    ? 'col-lg-3 col-md-3 col-sm-12'
                    : 'col-md-12'
                ">
                <div>
                    <div class="navbar-left " style="background-color: #F4EADE;">
                        <div class="cart-title mt-3">
                            Cart
                            <span class="badge badge-info ml-1 rounded-circle" style="background-color: rgb(245, 133, 113);">{{
                        countTotalCart
                      }}</span>
                        </div>
                    </div>
                </div>
                <div class="navbar-right border-right col-lg-9 mx-1 fixed-top" id="navbar-right" style="background-color: #F4EADE; display: flex; flex-direction: row-reverse; justify-content: space-between;">
                    <button type="button" class="btn m-0 p-0 hamburger text-decoration-none px-5">
                    <img
                      width="130"
                      height="25"
                      src="@/assets/images/harveys-logo.png"
                      alt="Harveys Menu"
                    />
                  </button>
                    <div class="navbar-title">
                        {{ currentRouteName === "Home" ? "Food Items" : currentRouteName }}
                    </div>
                    <div>
                        <button v-if="currentRouteName === 'Home' && roleId !== 3" v-b-modal.modal-cart-mobile class="btn py-0 position-relative" id="modal-cart">
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 16 16"
                        class="bi bi-cart"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"
                        />
                      </svg>
                      <span class="badge badge-info rounded-circle cart-ic-res">{{
                        countTotalCart
                      }}</span>
                    </button>
                        <button v-if="currentRouteName === 'Home'" @click="toggleSearch" class="btn m-0 p-0" href="#">
                      <img
                        v-if="showSearch"
                        width="20"
                        height="20"
                        src="@/assets/images/close.png"
                        alt="close"
                      />
                      <img
                        v-if="!showSearch"
                        width="25"
                        height="25"
                        src="@/assets/images/magnifying-glass.png"
                        alt="search"
                      />
                    </button>
                        <button class="btn mx-1" @click="reload">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="26"
                        height="26"
                        viewBox="0 0 30 30"
                      >
                        <path
                          d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"
                        ></path>
                      </svg>
                    </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- <ModalCartMobile /> -->
    </b-container>
</template>

<script>
// import ModalCartMobile from '@/components/molecules/ModalCartMobile';
import { mapState, mapGetters, mapMutations } from "vuex";
export default {
    name: "Navbar",
    components: {
        // ModalCartMobile,
    },
    methods: {
        ...mapMutations("product", ["SHOW_PART_SEARCH"]),
        toggleMenu() {
            const sidebar = document.querySelector(".sidebar");
            sidebar.classList.toggle("active");
            document.querySelector(".content-left").classList.toggle("active");
        },
        toggleSearch() {
            this.SHOW_PART_SEARCH(!this.showSearch);
        },
        reload() {
            window.location.reload()
        }
    },
    computed: {
        ...mapState("product", ["showSearch"]),
        ...mapGetters("cart", ["countTotalCart"]),
        ...mapState("auth", ["roleId"]),
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>

<style scoped>
.cart-ic-res {
    position: absolute !important;
    top: -2px;
    right: 6px;
}

.navbar-title {
    font-size: 24px;
    color: #0f4332;
    font-weight: bolder;
}
.navbar-left {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .navbar-left{
  margin-left:13rem;
}
@media screen and (max-width:1024px) {
.navbar-left{
  margin-left:7rem;
}
}
@media screen and (min-width:1440px) {
.navbar-left{
  margin-left:18rem;
}
}
@media screen and (max-width:768px) {
.navbar-left{
  margin-right:2rem;
}
} */
</style>
