<template>
  <div>
    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop :title="whatModal"
      :size="'md'" centered @hide="hideModal('')" v-if="getWhatModal == 'Refund'">
      <div class="row">
        <div class="mx-auto">Are you sure to refund this order ?</div>
      </div>
      <div class="container text-center mt-1">
        <div class="row justify-content-center">
          <div class="col">
            <div class="mx-auto">
              <b-button variant="primary" @click="hideModal('')" class="mr-2">Sure</b-button>
              <b-button variant="secondary" @click="hideModal('')">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop :title="whatModal"
      :size="'md'" centered @hide="hideModal('')" v-if="getWhatModal == 'Print'">
      <div class="receipt">
        <div class="receipt-header">
          <h2>Fun Wan</h2>
          <p>77 Robinson Road, 01-04<br>Singapore 068896</p>
          <p>Email: hello@harveys.sg</p>
          <hr>
        </div>

        <div class="receipt-info">
          <p>Order No: <strong>6-419</strong> <span class="float-right">DINE-IN</span></p>
          <p>Bill No: <strong>419</strong> <span class="float-right">2024-07-16</span></p>
        </div>

        <table class="table">
          <thead>
            <tr>
              <th>Item</th>
              <th class="text-right">Price</th>
              <th class="text-center">Qty</th>
              <th class="text-right">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Pokka Green Tea</td>
              <td class="text-right">1.8</td>
              <td class="text-center">1</td>
              <td class="text-right">1.8</td>
            </tr>
            <tr>
              <td>Build Your Menu</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">6.5</td>
            </tr>
            <tr>
              <td>Brown Rice</td>
              <td class="text-right">1.5</td>
              <td class="text-center">1</td>
              <td class="text-right">1.5</td>
            </tr>
            <tr>
              <td>Fried Pork Ribs</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
            <tr>
              <td>Marmite Sauce</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
            <tr>
              <td>Soup of the Day</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
            <tr>
              <td>Achar - Spicy Mixed Pickles</td>
              <td class="text-right">2</td>
              <td class="text-center">1</td>
              <td class="text-right">2</td>
            </tr>
            <tr>
              <td>Build Your Menu</td>
              <td class="text-right">2</td>
              <td class="text-center">1</td>
              <td class="text-right">2</td>
            </tr>
            <tr>
              <td>Cauliflower Rice (Low Carb)</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
            <tr>
              <td>Stir-Fry Beef</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
            <tr>
              <td>Salted Egg Sauce</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
            <tr>
              <td>Remove Sous Vide Egg</td>
              <td class="text-right">0</td>
              <td class="text-center">1</td>
              <td class="text-right">0</td>
            </tr>
          </tbody>
        </table>

        <div class="receipt-summary">
          <p class="d-flex justify-content-between">
            <span>Sub Total:</span>
            <span>$10.3</span>
          </p>
          <p class="d-flex justify-content-between">
            <span>Discount:</span>
            <span>$0</span>
          </p>
          <p class="d-flex justify-content-between">
            <span>GST(9%):</span>
            <span>$0.93</span>
          </p>
          <p class="d-flex justify-content-between">
            <strong>Grand Total:</strong>
            <strong>$11.23</strong>
          </p>
          <hr>
          <p>Card No: 000495 <span class="float-right">VISA</span></p>
        </div>

        <div class="receipt-footer">
          <p>Thank you and see you again</p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';

export default {
  name: 'ModalOrder',
  props: ['whatModal'],
  mixins: [mixins],
  data() {
    return {};
  },
  computed: {
    ...mapGetters('modal', ['getWhatModal']),
  },
  methods: {
    ...mapMutations('modal', ['SET_WHAT_MODAL', 'HIDE_MODAL']),

    hideModal(val) {
      this.SET_WHAT_MODAL(val);
    },

    showModal(val) {
      this.SET_WHAT_MODAL(val);
    },
  },
};
</script>

<style scoped>
.receipt {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
}

.receipt-header,
.receipt-footer {
  text-align: center;
}

.receipt-header h2,
.receipt-footer p {
  margin: 5px 0;
}

.table th,
.table td {
  padding: 0.5rem;
}
</style>
