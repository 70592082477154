<template>
  <div class="home">
    <b-container fluid>
      <b-row>
        <b-col md="12" class="px-0">
          <div class="content-left history pt-4 pb-0">
            <!-- <Sidebar /> -->
            <div class="main-left mb-2">
              <!-- <ListInfoHistory /> -->
              <div class="row mx-2">
                <div class="col-md-12 mb-5 mt-3">
                  <ReadyOrderCard />
                </div>
              </div>
            </div>
            <Sidebar />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import ListInfoHistory from '@/components/molecules/ListInfoHistory';
import Sidebar from '@/components/layout/MainSidebar';
import ReadyOrderCard from '@/components/organisms/OrderCard';
import PendingOrdersCard from '@/components/organisms/PendingOrdersCard';
import ChartCard from '@/components/organisms/ChartCard';
// @ is an alias to /src
import '@/assets/css/style.css';
export default {
  name: 'History',
  components: {
    Sidebar,
    ReadyOrderCard,
    ChartCard,
    ListInfoHistory,
    PendingOrdersCard
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
/* @media only screen and (width: 768px) {
.main-left{
  width:78%;
}
}
@media only screen and (min-width: 729px) {
.main-left{
  width:90%;
}
} */
</style>
