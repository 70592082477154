<template>
  <b-modal v-b-modal.modal-xl id="modal-primary" ref="modal-primary" hide-footer no-close-on-backdrop
    :title="'Edit Addons'" :size="'xl'" centered @hide="hideModal('')"
    v-if="getWhatModal === 'edit_addons_' + uniqueEditingModal">
    <div v-show="Object.keys(selectedAddonModifier).length == 0 " v-if="selectedProduct && selectedProduct.addons">
        <b-container fluid class="">
          <b-row>
            <b-col v-for="addon in selectedProduct.addons" :key="addon.id" :lg="12" :md="12" :sm="12" class="mb-4">
              <b-card>
                <b-card-header>
                  <span class="addon-description">{{ addon.modifier && addon.modifier.description }}</span>
                  <span class="addon-max-selection float-right badge badge-info">Max selection: {{ addon.max_selection
                  }}</span>
                  <span class="badge badge-primary ml-1" v-show="addon.required">Required</span>
                </b-card-header>
                <b-container fluid>
                  <b-row class="mt-3">
                    <b-col v-for="product in addon.addon_products" :key="product.id" :class="[
                      'border',
                      'rounded',
                      'p-3',
                      'cursor-pointer',
                      'addon-item',
                      {
                        selected: isProductSelected(addon, product),
                      },
                    ]" :lg="3" :md="6" :sm="12" @click="toggleProductSelection(addon, product)">
                      <h6 class="text-center">{{ product.product && product.product.name }}</h6>
                      <p class="text-center">${{ product.product && product.product.sale_price }}</p>
  
                      <div
                        v-if="product.product && product.product.stock && product.product.stock.tags && product.product.stock.tags.length > 0">
                        <img v-for="(tag, index) in JSON.parse(product.product.stock.tags)" :key="index" :src="tag"
                          :alt="'Tag ' + (index + 1)" :class="{ 'left-tag': index == 0, 'right-tag': index == 1 }"
                          width="30"/>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
        <b-button :disabled="isContinueButtonDisabled" @click="showModal('')" v-b-modal.modal-primary
          class="mt-3 float-right" variant="primary">
          Continue
        </b-button>
      </div>
      <!-- Addon modifier  -->
      <div v-show="Object.keys(selectedAddonModifier).length > 0">
        <b-container fluid class="">
          <b-row>
            <b-col v-for="addon in selectedAddonModifier.addons" :key="addon.id" :lg="12" :md="12" :sm="12" class="mb-4">
              <b-card>
                <b-card-header>
                  <span class="addon-description">{{ addon.modifier && addon.modifier.description }}</span>
                  <span class="addon-max-selection float-right badge badge-info">Max selection: {{ addon.max_selection
                  }}</span>
                  <span class="badge badge-primary ml-1" v-show="addon.required">Required</span>
                </b-card-header>
                <b-container fluid>
                  <b-row class="mt-3">
                    <b-col v-for="product in addon.addon_products" :key="product.id"  :class="[
                      'border',
                      'rounded',
                      'p-3',
                      'cursor-pointer',
                      'addon-item',
                      {
                        selected: isProductSelected(addon, product),
                      },
                    ]" :lg="3" :md="6" :sm="12" @click="toggleProductSelection(addon, product)" 
 >
                      <h6 class="text-center">{{ product.product && product.product.name }}</h6>
                      <p class="text-center">${{ product.product && product.product.sale_price }}</p>
  
                      <div
                        v-if="product.product && product.product.stock && product.product.stock.tags && product.product.stock.tags.length > 0">
                        <img v-for="(tag, index) in JSON.parse(product.product.stock.tags)" :key="index" :src="tag"
                          :alt="'Tag ' + (index + 1)" :class="{ 'left-tag': index == 0, 'right-tag': index == 1 }"
                          width="30" />
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
        <b-button :disabled="isContinueButtonDisabled" @click="clearSelectedAddons()" v-b-modal.modal-primary
          class="mt-3 float-right" variant="primary">
          Continue
        </b-button>
      </div>
  </b-modal>
</template>

<script>
import mixins from '@/components/mixins/swal';
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'EditAddonModal',
  props: ['whatModal', 'selectedProduct', 'editSelectedAddons', 'editSelectedAddonsObj', 'uniqueEditingModal', 'quantity'],
  mixins: [mixins],
  data() {
    return {
      selectedAddons: [],
      completeAddonsObj: [],
      selectedAddonModifier: {}
    };
  },

  watch: {
    selectedProduct: {
      immediate: true,
      handler() {
        this.initializeSelectedAddons();
      },
    },
  },

  computed: {
    ...mapGetters('modal', ['getWhatModal']),

    isContinueButtonDisabled() {
      for (const addon of this.selectedProduct.addons) {
        if (addon.required) {
          const selectedProductsInRow = this.selectedAddons.filter(
            (selectedProduct) => selectedProduct.addon_id === addon.id
          );
          if (selectedProductsInRow.length === 0) {
            return true;
          }
        }
      }

      return false;
    },
  },

  methods: {
    ...mapMutations('category', ['SET_PRODUCT_ADDONS']),
    ...mapMutations('modal', ['SET_WHAT_MODAL', 'HIDE_MODAL']),
    ...mapMutations('cart', ['ADD_TO_CART', 'REMOVE_PRODUCT_FROM_CART']),

    hideModal(val) {
      this.SET_WHAT_MODAL(val);
    },

    showModal(val) {
      this.SET_WHAT_MODAL(val);
      this.submitProduct();
    },

    clearSelectedAddons() {
      this.selectedAddonModifier = {}
    },

    submitProduct() {
      this.REMOVE_PRODUCT_FROM_CART(this.selectedProduct.id, this.editSelectedAddons);
      this.ADD_TO_CART({
        product: {
          id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          sale_price: this.selectedProduct.sale_price,
          preview_url: this.selectedProduct.preview_url,
        },
        addons_id: this.selectedAddons,
        addons: this.completeAddonsObj,
        qty: this.quantity,
        productWithAddons: this.selectedProduct,
      });
    },

    initializeSelectedAddons() {
      this.selectedAddons = this.editSelectedAddons || [];
      this.completeAddonsObj = this.editSelectedAddonsObj || [];
    },

    toggleProductSelection(addon, product) {
      const isAlreadySelected = this.isProductSelected(addon, product);
      const isRequiredAndSingleSelection = addon.required && addon.max_selection === 1;
      const isNotRequiredAndSingleSelection = !addon.required && addon.max_selection === 1;
      const isRequiredAndMultipleSelection = addon.required && addon.max_selection > 1;

      if (isRequiredAndSingleSelection) {
        if (!isAlreadySelected) {
          this.switchSelection(addon, product);
        }
      } else if (isNotRequiredAndSingleSelection) {
        if (this.getSelectedCount(addon) > 1 || !isAlreadySelected) {
          this.switchSelection(addon, product);
        } else {
          this.unselectProduct(addon, product);
        }
      } else if (isRequiredAndMultipleSelection) {
        if (this.getSelectedCount(addon) > 1 || !isAlreadySelected) {
          this.toggleSelection(addon, product);
        }
      } else {
        this.toggleSelection(addon, product);
      }

      if(product.selected && product.product.addons && product.product.addons.length > 0) {
        this.selectedAddonModifier = product.product
      }
    },

    toggleSelection(addon, product) {
      if (this.isProductSelected(addon, product)) {
        this.unselectProduct(addon, product);
      } else {
        this.selectProduct(addon, product);
      }
    },

    switchSelection(addon, product) {
      const selectedProducts = this.selectedAddons.filter(
        (selectedProduct) => selectedProduct.addon_id === addon.id
      );

      if (selectedProducts.length > 0) {
        const previousProduct = selectedProducts[0];
        this.unselectProduct(addon, { product: { id: previousProduct.addon_product_id } });
      }

      this.selectProduct(addon, product);
    },

    getSelectedCount(addon) {
      return this.selectedAddons.filter((selectedProduct) => selectedProduct.addon_id === addon.id).length;
    },

    isProductSelected(addon, product) {
      return this.selectedAddons.some(
        (selectedProduct) =>
          selectedProduct.addon_id === addon.id &&
          selectedProduct.addon_product_id === product.product.id
      );
    },

    selectProduct(addon, product) {
      this.selectedAddons.push({ addon_id: addon.id, addon_product_id: product.product.id });
      this.completeAddonsObj.push(product);
      product.selected = true;
    },

    unselectProduct(addon, product) {
      const addonProductIdToRemove = product.product.id;

      this.selectedAddons = this.selectedAddons.filter(
        (selectedProduct) =>
          !(
            selectedProduct.addon_id === addon.id &&
            selectedProduct.addon_product_id === addonProductIdToRemove
          )
      );

      this.completeAddonsObj = this.completeAddonsObj.filter(
        (completeProduct) =>
          !(
            completeProduct.product_modifier_id === addon.id &&
            completeProduct.product_id === addonProductIdToRemove
          )
      );

      if (product.product.addons && product.product.addons.length > 0) {
        for(let i = 0; i < product.product.addons.length; i++) {
          for(let j = 0; j < product.product.addons[i].addon_products.length; j++) {
           this.unselectProduct(product.product.addons[i], product.product.addons[i].addon_products[j])
          }
        }
      }

      product.selected = false;
    },

    isProductDisabled(addon, product) {
      const selectedCount = this.selectedAddons.filter(
        (selectedProduct) => selectedProduct.addon_id === addon.id
      ).length;
      return selectedCount >= addon.max_selection && !this.isProductSelected(addon, product);
    },
  },
};
</script>

<style scoped>
.btn-lg {
  font-size: 1.5rem;
  width: 100%;
  margin: 0, 10;
}

.form-control-lg {
  font-size: 1.5rem;
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
}

.mb-2 {
  margin-bottom: 10px;
}

.addon-description {
  font-weight: bold;
}

.addon-max-selection {
  font-size: 0.8em;
}

.addon-item {
  transition: box-shadow 0.3s ease; 
  background-color: darkcyan !important;
}

.addon-item:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  
}

.selected {
  background-color: #6de28a;
  color: #fff;
}

.disabled {
  background-color: #bfbcbc;
  pointer-events: none;
}

.cursor {
  cursor: pointer;
}
</style>
